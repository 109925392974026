<template>
  <div class="list-manage personal-info">
    <section class="list-manage-table">
      <div class="table-title" style="border-bottom: none">
        <span class="back" @click="back()">返回</span>
        <span class="title">{{ obj.title }}</span>
      </div>
      <div class="authortime">
        <div class="box">
          <span class="author">发布者:{{ obj.create_by }}</span>
          <span>时间:{{ obj.create_time }}</span>
        </div>
      </div>
      <div class="content">
        <div>资讯内容:</div>
        <div>
          <p v-html="obj.content"></p>
          <!-- <p @click="todd(obj.trigger_json.id)" class="cursor" v-if="obj.trigger_json!=null && obj.trigger_json.type == 'order'" >
          点击跳转订单详情</p> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getsysmessage } from "@/api/myorder";
import { getmsgnum } from "@/api/user";

export default {
  data() {
    return {
      pageNumber: "",
      obj: {},
    };
  },
  created() {
    this.sysmessage();
  },
  mounted() {
    this.get_msgnum();
  },

  methods: {
    todd(order_id){
            this.$router.push({
            path: `/personalCenter/orderDetails`,
            query: { id: order_id },
      });
    },
    get_msgnum() {
      getmsgnum().then((res) => {
        if (res.status == 200) {
          this.msgnuma = res.data[0].num;
          console.log(res.data[0].num);
        } else {
          //this.$message.error(res.msg);
        }
      });
    },
    back() {
      this.$router.push({
        name: "personalMessage",
        params: { pageNumber: this.pageNumber },
      });
    },
    sysmessage() {
      this.pageNumber = this.$route.query.pageNumber;
      let id = this.$route.query.id;
      
      getsysmessage({ inputs: { message_id: id } }).then((res) => {
        this.obj = res.data;

        if(res.data.trigger_json!=null && res.data.trigger_json!=""){
            this.obj.trigger_json =  JSON.parse(res.data.trigger_json)
        }
        console.log("========", this.obj );
      });
    },
  },
};
</script>

<style scoped>
.list-manage .list-manage-table .table-title {
  position: relative;
  padding: 24px 50px 20px;
  display: flex;
  justify-content: center;
}
.back {
  position: absolute;
  left: 20px;
  top: 40px;
  color: #169bd5;
}

span.back:hover {
  cursor: pointer;
}

.authortime {
  width: 880px;
  border-top: 1px solid #e8e8e8;
  margin: 0 auto;
}
.box {
  padding: 20px;
  display: flex;
  justify-content: center;
}
.author {
  padding-right: 100px;
}
.content {
  width: 800px;
  margin: 0 auto;
  font-size: 18px;
}
</style>
